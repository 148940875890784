import React from "react"
import Col from "react-bootstrap/Col"

import { ContainerRowCol } from "@components/Containers/positioning-wrappers"
import SEO from "@components/SEO/seo"
import { ContactEmail } from "@components/contact-email"
import { StaticNavbarLayout } from "@components/layouts/static-navbar-layout"

import { SECTION } from "@helpers/constants/style"

const PrivacyPolicyPage = () => (
  <StaticNavbarLayout>
    <SEO
      title="Privacy Policy"
      keywords={["Privacy Policy", "Security", "Personal Data"]}
    />
    <ContainerRowCol className={SECTION}>
      <Col xs={12}>
        <h1>Privacy Policy</h1>
        <p>
          EDUCATS PTY LTD is committed to providing quality services to you and
          this policy outlines our ongoing obligations to you in respect of how
          we manage your Personal Information. We have adopted the Australian
          Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the
          Privacy Act). The NPPs govern the way in which we collect, use,
          disclose, store, secure and dispose of your Personal Information. A
          copy of the Australian Privacy Principles may be obtained from the
          website of The Office of the Australian Information Commissioner at{" "}
          <a
            href="https://www.oaic.gov.au/privacy/australian-privacy-principles"
            target="__blank"
          >
            https://www.oaic.gov.au/
          </a>
        </p>
        <h2>What is Personal Information and why do we collect it?</h2>
        <p>
          Personal Information is information or an opinion that identifies an
          individual. Examples of Personal Information we collect include:
          names, addresses, email addresses, phone and facsimile numbers
        </p>
        <p>
          This Personal Information is obtained in many ways including
          interviews, correspondence, by telephone and facsimile, by email, via
          our website{" "}
          <a href="www.educats.com.au" target="__blank">
            www.educats.com.au
          </a>
          , social media, from media and publications, from other publicly
          available sources, from cookies and from third parties. We don’t
          guarantee website links or policy of authorised third parties.
        </p>
        <p>
          We collect your Personal Information for the primary purpose of
          providing our services to you, providing information to our clients
          and marketing. We may also use your Personal Information for secondary
          purposes closely related to the primary purpose, in circumstances
          where you would reasonably expect such use or disclosure. You may
          unsubscribe from our mailing/marketing lists at any time by contacting
          us in writing.
        </p>
        <p>
          When we collect Personal Information we will, where appropriate and
          where possible, explain to you why we are collecting the information
          and how we plan to use it.
        </p>
        <h2>Sensitive Information</h2>
        <p>
          Sensitive information is defined in the Privacy Act to include
          information or opinion about such things as an individual's racial or
          ethnic origin, political opinions, membership of a political
          association, religious or philosophical beliefs, membership of a trade
          union or other professional body, criminal record or health
          information.
        </p>
        <p>Sensitive information will be used by us only:</p>
        <ul>
          <li>For the primary purpose for which it was obtained</li>
          <li>
            For a secondary purpose that is directly related to the primary
            purpose
          </li>
          <li>With your consent; or where required or authorised by law.</li>
        </ul>
        <h2>Disclosure of Personal Information</h2>
        <p>
          Your Personal Information may be disclosed in a number of
          circumstances including the following:
        </p>
        <ul>
          <li>Third parties where you consent to the use or disclosure; and</li>
          <li>Where required or authorised by law.</li>
        </ul>
        <h2>Security of Personal Information</h2>
        <p>
          Your Personal Information is stored in a manner that reasonably
          protects it from misuse and loss and from unauthorized access,
          modification or disclosure.
        </p>
        <p>
          When your Personal Information is no longer needed for the purpose for
          which it was obtained, we will take reasonable steps to destroy or
          permanently de-identify your Personal Information. However, most of
          the Personal Information is or will be stored in client files which
          will be kept by us for a minimum of 7 years.
        </p>
        <h2>Access to your Personal Information</h2>
        <p>
          You may access the Personal Information we hold about you and to
          update and/or correct it, subject to certain exceptions. If you wish
          to access your Personal Information, please contact us in writing.
        </p>
        <p>
          EDUCATS PTY LTD will not charge any fee for your access request, but
          may charge an administrative fee for providing a copy of your Personal
          Information. In order to protect your Personal Information we may
          require identification from you before releasing the requested
          information.
        </p>
        <h2>Maintaining the Quality of your Personal Information</h2>
        <p>
          It is an important to us that your Personal Information is up to date.
          We will take reasonable steps to make sure that your Personal
          Information is accurate, complete and up-to-date. If you find that the
          information we have is not up to date or is inaccurate, please advise
          us as soon as practicable so we can update our records and ensure we
          can continue to provide quality services to you.
        </p>
        <h2>Policy Updates</h2>
        <p>
          This Policy may change from time to time and is available on our
          website.
        </p>
        <h2>Privacy Policy Complaints and Enquiries</h2>
        <p>
          If you have any queries or complaints about our Privacy Policy please
          contact us at <ContactEmail />
        </p>
      </Col>
    </ContainerRowCol>
  </StaticNavbarLayout>
)

export default PrivacyPolicyPage
